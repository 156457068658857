$cdnBase: '';/* Extra small devices (portrait phones, less than 576px) */
/* No media query since this is mobile first */
@import "modules/variables";
@import "modules/palette";

// Then include Bootstrap
@import 'partials/bootstrap';

#pageHeader, #pageFooter, #scrollToTop {
    display: none;
}

#noscriptPageHeader {
    font-family: 'Alegreya Sans', sans-serif;
    font-weight: normal;
    min-height: 66px;
    background-color: #15639E;
    position: absolute;
    top: 0;
    width: 100%;
}


#noscriptPageHeader nav {
  min-height: 66px;
  width: 100%;
  position: relative;
  display: flex;

  ul {
    text-decoration: none;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}

#noscriptPageHeader nav ul li {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  transition: ease all .25s;

  &:hover {
    background: #004A7F;
  }
}

#noscriptPageHeader .nav-link {
  text-transform: uppercase;
  letter-spacing: 0.08em;
  font-weight: 600;
  padding: 1em;
  color: #E6E6E6;
  text-decoration: none;
  font-family: 'Alegreya Sans', sans-serif;
  background-color: transparent;

  &::after{
    content: '';
    display: none;
  }
  &:active, &:hover {
    color:  #D0E534;
  }
}

#noscriptPageHeader .page-header-logo {
    background: #3198D7;
    left: 0;
    top: 0;
    height: 132px;
    width: 132px;
    z-index: 9000;
    padding: 0.5em;
    display: none;

    img {
        width: 100%;
    }
}

#noscriptPageHeader .page-header-logo-mobile {
  width: 100%;
  height: 30px;
  background-color: #3198D7;
  display: flex;
  justify-content: space-between;
  align-content: center;

  a {
    display: block;
    margin: 0 auto;
  }

  img {
    width: 100%;
  }
}

#noscriptPageHeader .logo-horizontal {
    height: 30px;
    padding: 3px;
}

.noscript-interior-masthead {
  display: none;
}

#noscriptPageFooter {
    padding-top: 1em;

    &.bg-dark {
        background-color: $color-footer-large-area !important;
    }

    &.bg-dark.text-light {
        a {
            &:active,
            &:hover,
            &:link,
            &:visited {
                color: $color-white;
            }
        }
    }

    .footer-title{
      text-transform: uppercase;
      font-size: 1.25em;
      font-weight: bold;
      margin-bottom: .5em;
    }

    .mission-statment{
      text-align: center;
      justify-content: center;
    }

    .none-discrimination{
      text-align: center;
    }

    .footer-logo-img{
      margin: 3em 0 .5em;
    }
    .university-address-area{
      text-align: center;
      justify-content: center;

      p {
        margin-bottom: .5em;
      }
    }

    .university-sub-addresses {
      text-align: center;
      justify-content: center;
      p {
        text-align: center;
        &:not(:last-of-type){
          margin-bottom: .25em;
        }
      }
    }

    .admissions-inquiry-title{
      margin-top: 1em;
      font-size: 1.25em;
      font-weight: bold;
    }

    .location-title{
      font-weight: bold;
    }

    .vertical-bar{
      display: inline-block;
      height: 100%;
      border: 1px solid $color-white;
      margin: 0 .5em;
    }

    .sitka {
      border-left: 2px solid $color-white;
    }

    .footer-nav {
        line-height: 1.7;

        li {
            position: relative;
            padding-left: 1em;
        }

        li::before {
            position: absolute;
            top: 0;
            left: 0;
            content: "\f105";
            font-family: 'FontAwesome';
            color: $color-white;
        }
    }

    .footer-bottom {
        background-color: $color-footer-boiler-plate;
        box-shadow: inset 0 15px 20px -20px rgba(0,39,67,0.8);
    }

    .footer-further-bottom {
        font-size: .85em;
        font-style: italic;
        box-shadow: inset 0 15px 20px -20px rgba(5,58,99,0.8);
        a {
          font-weight: bold;
        }
    }

    .footer-helpful-links {
        li:not(:last-of-type) a {
            padding-right: 1em;
            color: $color-less-white-footer-links;
            border-right: 1px solid rgba(255, 255, 255, 0.4);
        }
    }

    .boilerplate {
        font-size: 90%;

        p {
            margin-bottom: 0.7em;
            line-height: 1.3;
        }
    }

    .social-media-links {
        a:active,
        a:hover,
        a:link,
        a:visited {
            color: inherit;
        }
    }

    small {
        font-size: 75%;
    }

    .social-icon-sm {
        margin: 0 5px 5px 0;
        width: 30px;
        height: 30px;
        font-size: 18px;
        line-height: 30px !important;
        color: #555;
        text-shadow: none;
        border-radius: 3px;
        overflow: hidden;
        display: block;
        float: left;
        text-align: center;
    }

    .social-icon-sm:hover {
        background-color: $color-footer-large-area-lighter-for-hover !important;
        text-decoration: none;
    }

    .social-icon-sm i {
        display: block;
        transition: all 0.3s ease;
        line-height: 30px;
        position: relative;
    }

    .social-icon-sm i:last-child {
        color: $color-white !important;
    }

    .social-icon-sm:hover i:first-child {
        margin-top: -30px;
    }

    .si-dark {
        background-color: $color-footer-large-area;
        color: $color-white !important;
    }
}

@include media-breakpoint-up(xs){
  #noscriptPageFooter .footer-logo-img img{
    width: 85%;
  }
}

@include media-breakpoint-down(sm){
  #noscriptPageFooter .link-section {
    padding-left: 2em ;
  }
}

@include media-breakpoint-up(md){
  #noscriptPageFooter .link-section:not(:first-of-type){
    border-left: 2px solid $color-white;
  }

  #noscriptPageFooter .footer-logo-img img{
    width: 55%;
  }
}

@include media-breakpoint-up(lg) {
  #noscriptPageHeader .page-header-logo-mobile {
    display:none;
  }

  #noscriptPageHeader .page-header-logo {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    position: absolute;
  }

  #noscriptPageHeader nav {
    justify-content: flex-end;
  }

  #noscriptPageHeader nav ul {
    flex-direction: row;
    height: 66px;
    width: auto;
  }

  .noscript-interior-masthead {
    display: block;
  }
}
